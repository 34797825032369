<template>
  <div>
    <h1 class="page-title">
      Proceso de Abogado
    </h1>
    <div class="col-10 m-auto">
      <Widget class="shadow-xl">
        <div class="flex space-x-4 mb-3">
          <div class="flex-1">
            <strong>Radicado</strong>
            <div>{{ processLawyer.settled }}</div>
          </div>
          <div class="flex-1">
            <strong>Abogado</strong>
            <div v-if="processLawyer.lawyer">
              {{ processLawyer.lawyer.full_name }}
            </div>
          </div>
          <div class="flex-1">
            <strong>Cliente</strong>
            <div v-if="processLawyer.client">
              {{ processLawyer.client.full_name }}
            </div>
          </div>
        </div>
        <div class="flex space-x-4 mb-3">
          <div class="flex-1">
            <strong>Juzgado</strong>
            <div v-if="processLawyer.court">{{ processLawyer.court.name }}</div>
          </div>
          <div class="flex-1">
            <strong>Juez</strong>
            <div v-if="processLawyer.judge">
              {{ processLawyer.judge.name }}
            </div>
          </div>
          <div class="flex-1">
            <strong>Corte de título de entrega</strong>
            <div>
              {{ processLawyer.delivery_title_court }}
            </div>
          </div>
        </div>
        <div class="flex space-x-4 mb-3">
          <div class="flex-1">
            <strong>Tipo de proceso</strong>
            <div v-if="processLawyer.process_type">
              {{ processLawyer.process_type.name }}
            </div>
          </div>
          <div class="flex-1" v-if="processLawyer.concept">
            <strong>Último estado</strong>
            <div>
              {{ processLawyer.concept.concept }}
            </div>
          </div>
          <div class="flex-1">
            <strong>Valor del proceso</strong>
            <div>${{ processLawyer.process_value | numericFormat }}</div>
          </div>
        </div>
        <div class="flex space-x-4 mb-3">
          <div class="flex-1">
            <strong>Fecha de inicio</strong>
            <div>
              {{ processLawyer.start_date }}
            </div>
          </div>
          <div class="flex-1">
            <strong>Fecha de admisión</strong>
            <template v-if="processLawyer.admission_date != null">
              <div>{{ processLawyer.admission_date }}</div>
            </template>
            <template v-else>
              <div>Sin asignar</div>
            </template>
          </div>
          <div class="flex-1">
            <strong>Prenda de garantía</strong>
            <div>
              {{ processLawyer.warranty_garment }}
            </div>
          </div>
        </div>
        <div class="flex space-x-4 mb-4">
          <div class="flex-1">
            <strong>Observaciones</strong>
            <div>
              {{ processLawyer.notes_observations }}
            </div>
          </div>
        </div>
        <div class="flex space-x-4 mb-3">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow()"
          >
            <i class="glyphicon glyphicon-pencil transform -translate-y-1"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            title="Documentos"
            @click="selectedRow = processLawyer"
            v-b-modal.modal-documents-form
          >
            <i
              class="glyphicon glyphicon-folder-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleTraceprocesslawyer()"
          >
            <i class="fas fa-layer-group"></i>
          </b-button>
        </div>
      </Widget>
    </div>
    <base-modal-documents-form title="Documentos" :row="selectedRow">
      <template v-slot:formfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
    </base-modal-documents-form>
    <process-lawyer-form
      :handlePostSubmit="handlePostSubmit"
      :record="processLawyer"
      :idModal="idModal"
      :isDisabled="isDisabled"
      :processlawyerId="processlawyerId"
    />
  </div>
</template>

<script>
import { customComponentActions } from "@/mixins/actions.js";
import ProcessLawyerForm from "./ProcessLawyerForm";
import BaseModalDocumentsForm from "@/components/Base/BaseModalDocumentsForm";
import { deserialize } from "jsonapi-fractal";
export default {
  inject: ["processLawyerRepository"],

  mixins: [customComponentActions],

  components: {
    ProcessLawyerForm,
    BaseModalDocumentsForm
  },

  data() {
    const me = this;
    return {
      localOptions: {
        repository: {
          name: "processLawyerRepository"
        },
        primary: null
      },
      processlawyerId: me.$route.params.processlawyerId,
      processLawyer: {},
      selectedRow: null,
      idModal: "modal-form",
      disabled: false,
      isDisabled: false
    };
  },

  methods: {
    async handleProcessLawyer() {
      const me = this;

      let processLawyer = await me.processLawyerRepository.find(
        me.processlawyerId
      );

      me.processLawyer = deserialize(processLawyer.data);
    },

    handlePostSubmit() {
      this.handleProcessLawyer();
    },

    handleTraceprocesslawyer() {
      const me = this;
      this.$router.push({
        name: "Tracesprocesslawyers",
        params: {
          processlawyerId: me.processLawyer.id
        }
      });
    },

    setSelectedRow() {
      const me = this;

      me.processLawyer.is_personal == false
        ? (me.isDisabled = true)
        : (me.isDisabled = false);
      me.selectedRow = me.$parent.selectedRow = me.processLawyer;
      me.modalProcessLawyerForm();
    },

    modalProcessLawyerForm() {
      const me = this;
      me.$bvModal.show(me.idModal);
    }
  },

  mounted() {
    const me = this;
    me.localOptions.primary = me.processlawyerId;
    me.handleProcessLawyer();
  }
};
</script>
